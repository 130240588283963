// useShoppingCart  customHook ref:simple

import { useShoppingCartStore } from "@multicines/stores";
import { addProduct, deleteShoppingCart } from "artisn/shopping-cart";
import { listenShoppingCart } from "artisn/shopping-cart";
import { removeProduct, replaceProduct } from "artisn/shopping-cart";
import { setProduct } from "artisn/shopping-cart";
import { updateShoppingCart } from "artisn/shopping-cart";
import { createShoppingCart, getShoppingCart } from "artisn/shopping-cart";
import { useMemo } from "react";

import { UseShoppingCartValues } from "./useShoppingCart.types";
import CONSTANTS from "config/constants";
import { useAuthStore } from "stores/auth/auth.store";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

const useShoppingCart = ():
  | UseShoppingCartValues
  | Record<keyof UseShoppingCartValues, undefined> => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const setShoppingCart = useShoppingCartStore(store => store.setShoppingCart);

  const config = useMemo(() => {
    if (!uid) return null;
    if (typeof isAnonymous === "undefined") return null;

    return {
      accountId: ACCOUNT_ID,
      anonymous: isAnonymous,
      customerId: uid
    };
  }, [isAnonymous, uid]);

  return useMemo(() => {
    if (!config) return {};

    return {
      listenShoppingCart: () => {
        return listenShoppingCart(cart => {
          if (cart?.id === "" || cart?.id === null) return;
          setShoppingCart(cart ?? undefined);
        }, config);
      },
      createShoppingCart: (options, overrides) => {
        const newConfig = {
          ...config,
          ...options
        };
        return createShoppingCart(newConfig, overrides);
      },
      getShoppingCart: options => {
        const newConfig = {
          ...config,
          ...options
        };
        return getShoppingCart(newConfig);
      },
      deleteShoppingCart: options => {
        const newConfig = {
          ...config,
          ...options
        };
        return deleteShoppingCart(newConfig);
      },
      updateShoppingCart: cb => {
        return updateShoppingCart(cb, config);
      },
      addProduct: (product, options) => {
        const newConfig = {
          ...config,
          ...options
        };
        return addProduct(product, newConfig);
      },
      removeProduct: (product, options) => {
        const newConfig = {
          ...config,
          ...options
        };
        return removeProduct(product, newConfig);
      },
      setProduct: (product, options) => {
        const newConfig = {
          ...config,
          ...options
        };
        return setProduct(product, newConfig);
      },
      replaceProduct: (product, options) => {
        const newConfig = {
          ...config,
          ...options
        };
        return replaceProduct(product, newConfig);
      }
    };
  }, [config, setShoppingCart]);
};

export default useShoppingCart;
