import { AppParameters, useAppParametersStore } from "@multicines/stores";
import { NotificationsContainer } from "artisn-ui-react";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useRef } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Hydrate } from "react-query/hydration";
import { ThemeProvider as ThemeProviderLib } from "styled-components";

import { ProvidersProps as Props } from "./Providers.types";
import GlobalStyles from "../../styles/base";
import Error from "components/error/Error/Error";
import { db } from "config/artisn.config";
import { ErrorBoundary } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import { getQueryClient } from "config/reactQuery.config";
import ArtisnProvider from "containers/ArtisnProvider/ArtisnProvider";
import { useWebViewRedirect } from "hooks/useWebViewRedirect";
import { useGlobalsStore } from "stores/globals/globals.store";
import { useThemeStore } from "stores/theme/theme.store";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

const Providers: React.FC<Props> = props => {
  const { children, dehydratedState } = props;
  const selectedTheme = useThemeStore(state => state.selectedTheme);
  const setDisabledWeb = useGlobalsStore(state => state.setDisabledWeb);
  const setShowHighTrafficModal = useGlobalsStore(
    state => state.setShowHighTrafficModal
  );
  const queryClientRef = useRef<QueryClient>(getQueryClient());
  const setAppParameters = useAppParametersStore(
    state => state.setAppParameters
  );

  useWebViewRedirect();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, `appParameters/${ACCOUNT_ID}`),
      doc => {
        if (doc.exists()) {
          const fields = doc.data() as AppParameters;
          setAppParameters(fields);
          const disabledWeb = fields?.disabledWeb;
          const activeHighTrafficModal = fields?.activeHighTrafficModal;
          setShowHighTrafficModal(activeHighTrafficModal);
          setDisabledWeb(!!disabledWeb);
        }
      }
    );
    return unsubscribe;
  }, [setAppParameters, setDisabledWeb, setShowHighTrafficModal]);

  return (
    <>
      <GlobalStyles theme={selectedTheme} />
      <ThemeProviderLib theme={selectedTheme}>
        <ErrorBoundary FallbackComponent={Error}>
          <QueryClientProvider client={queryClientRef.current}>
            <Hydrate state={dehydratedState}>
              {/* {process.env.NODE_ENV !== "test" ? <ReactQueryDevtools /> : null} */}
              <ArtisnProvider>
                <NotificationsContainer />
                {children}
              </ArtisnProvider>
            </Hydrate>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProviderLib>
    </>
  );
};

Providers.defaultProps = {};

export default Providers;
