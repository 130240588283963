// useCancelOrder customHook

import { fetchRebuildCart } from "@multicines/services";
import { useShoppingCartStore } from "@multicines/stores";
import { useCallback, useMemo } from "react";

import usePendingOrder from "./usePendingOrder";
import { leaveBreadcrumb, notify } from "config/bugsnag.config";
import { useAuthStore } from "stores/auth/auth.store";

const useCancelOrder = () => {
  const uid = useAuthStore(state => state.uid);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const { getIdIncompleteOrder, getCancelStatus } = usePendingOrder();

  const { id: shoppingCartId } = shoppingCart ?? {};

  const cancelPendingOrder = useCallback(
    async (rebuildCartSuccess?: () => void) => {
      if (!uid) return;

      try {
        leaveBreadcrumb("Fetching incomplete orders");
        const pendingId = await getIdIncompleteOrder();
        if (!pendingId) return;

        leaveBreadcrumb("Canceling tickets order");
        const cancelStatus = await getCancelStatus(pendingId);

        if (cancelStatus === "REJECTED") {
          await fetchRebuildCart(pendingId, shoppingCartId);
          rebuildCartSuccess?.();
        }
      } catch (e) {
        notify(e, "Cancel tickets/snacks Order");
      }
    },
    [getCancelStatus, getIdIncompleteOrder, shoppingCartId, uid]
  );

  return useMemo(() => ({ cancelPendingOrder }), [cancelPendingOrder]);
};

export default useCancelOrder;
