import { useFetchUser } from "@multicines/services";
import { useFetchUserSettings } from "@multicines/services";
import { usePostUserSettings } from "@multicines/services";
import { usePutUser } from "@multicines/services";
import { usePutUserSettings } from "@multicines/services";
import { useAppParametersStore, useCountriesStore } from "@multicines/stores";
import { changeTimezone } from "@multicines/utils";
import dayjs from "dayjs";
import MulticlubSVG from "images/info-yellow.svg";
import LinkSVG from "images/link-primary.svg";
import React, { useEffect, useState } from "react";

import Styles from "./PrivacyPolitics.styles";
import { PrivacyPoliticsProps as Props } from "./PrivacyPolitics.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import Divider from "components/global/Divider/Divider";
import { notify } from "config/bugsnag.config";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";
import { useModalsStore } from "stores/modals/modals.store";

const PrivacyPolitics: React.FC<Props> = props => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const analyticsHook = useAnalytics({});
  const t = useI18n();
  const { data: user } = useFetchUser({ isAnonymous, uid });
  const { data: userSettings, isLoading: isLoadingUserSettings } =
    useFetchUserSettings({ isAnonymous, uid }, false);
  const putUser = usePutUser({ isAnonymous, uid });
  const postUserSettings = usePostUserSettings({ isAnonymous, uid });
  const appParameters = useAppParametersStore(state => state.appParameters);
  const { legalTerms } = appParameters ?? {};
  const { termsAndConditions } = legalTerms ?? {};

  const { mutateAsync: createUserSettings } = postUserSettings;
  const { reset: resetCreateUserSettings } = postUserSettings;
  const { isLoading: isLoadingCreateUserSettings } = postUserSettings;

  const updateUserSettings = usePutUserSettings({ isAnonymous, uid });
  const { mutateAsync: updateUserSettingsAction } = updateUserSettings;
  const { reset: resetUpdateUserSettings } = updateUserSettings;
  const { isLoading: isLoadingUpdateUserSettings } = updateUserSettings;
  const setPrivacyPoliticsModalVisible = useModalsStore(
    state => state.setPrivacyPoliticsModalVisible
  );
  const privacyPoliticsModalVisible = useModalsStore(
    state => state.privacyPoliticsModalVisible
  );
  const selectedCountry = useCountriesStore(state => state.selectedCountry);
  const { id: selectedCountryId } = selectedCountry ?? {};
  const [dataTermsAccepted, setDataTermsAccepted] = useState(false);
  const [communicationTermsAccepted, setCommunicationTermsAccepted] =
    useState(false);

  const { logViewPolitics, logUpdatePolitics } = analyticsHook;

  const { mutateAsync: updateUser, isLoading: isLoadingPutUser } = putUser;
  const { reset: resetPutUser } = putUser;

  const { settings } = userSettings ?? {};
  const { pushNotifications } = settings ?? {};

  const isLoading =
    isLoadingPutUser ||
    isLoadingUserSettings ||
    isLoadingUpdateUserSettings ||
    isLoadingCreateUserSettings;

  const buttonHandler = async () => {
    logViewPolitics();

    if (!dataTermsAccepted) {
      return;
    }

    if (isAnonymous) {
      setPrivacyPoliticsModalVisible(false);
      return;
    }

    if (!user || !uid) return;

    const privacyAcceptDate = dayjs(changeTimezone(new Date())).toISOString();
    const newUser = {
      ...user,
      active: !!user.active,
      country: { id: selectedCountryId },
      privacyAcceptDate
    };

    try {
      await updateUser(newUser);
      resetPutUser();
      logUpdatePolitics({
        uid,
        date: privacyAcceptDate
      });
    } catch (e) {
      notify(e, "updateUser - PrivacyPolitics");
      console.error(e.message);
    }

    try {
      if (!userSettings) {
        createUserSettings({
          settings: {
            emailNotifications: communicationTermsAccepted,
            pushNotifications: pushNotifications ?? false
          },
          device_token: null
        });
        resetCreateUserSettings();
        setPrivacyPoliticsModalVisible(false);
        return;
      }
      await updateUserSettingsAction({
        settings: {
          emailNotifications: communicationTermsAccepted,
          pushNotifications: pushNotifications ?? false
        }
      });
      resetUpdateUserSettings();
      setPrivacyPoliticsModalVisible(false);
    } catch (e) {
      notify(e, "buttonHandler - PrivacyPolitics");
      console.error(e.message);
    }
  };

  useEffect(() => {
    setDataTermsAccepted(false);
    setCommunicationTermsAccepted(false);
  }, [privacyPoliticsModalVisible]);

  return (
    <Styles
      className="PrivacyPolitics"
      opened={privacyPoliticsModalVisible}
      color="transparent"
      closeOnClickOutside={false}
    >
      <p className="PrivacyPolitics__text__paragraph">
        {t.politics.description}
      </p>
      <Divider className="PrivacyPolitics__separator" />
      <Checkbox
        className="PrivacyPolitics__checkbox"
        label={
          <p className="PrivacyPolitics__terms">
            <span>
              {t.politics.termsPre}{" "}
              <a
                href={termsAndConditions}
                target="_blank"
                rel="noreferrer"
                className="PrivacyPolitics__checkbox-underline"
              >
                {t.politics.dataTerms}
                <LinkSVG className="PrivacyPolitics__icon" />
              </a>
            </span>
          </p>
        }
        name="checkbox"
        defaultChecked={dataTermsAccepted}
        onChange={() => setDataTermsAccepted(prev => !prev)}
      />
      <Checkbox
        className="PrivacyPolitics__checkbox"
        label={
          <span className="PrivacyPolitics__terms">{t.politics.terms}</span>
        }
        name="checkbox"
        defaultChecked={communicationTermsAccepted}
        onChange={() => setCommunicationTermsAccepted(prev => !prev)}
      />
      <div className="PrivacyPolitics__containerMulticlub">
        <div>
          <MulticlubSVG className="PrivacyPolitics__info" />
        </div>
        <div className="PrivacyPolitics__multiclubText">
          {t.politics.multiclubBenefit}
        </div>
      </div>
      {/* <Checkbox
        className="PrivacyPolitics__checkbox"
        label={
          <p className="PrivacyPolitics__terms">
            <span>{t.profile.notification.activateNotifications}</span>
          </p>
        }
        name="checkbox"
        defaultChecked={notificationAccepted}
        onChange={() => setNotificationAccepted(prev => !prev)}
      /> */}
      <Button
        mode="PRIMARY"
        className="PrivacyPolitics__button"
        onClick={buttonHandler}
        isLoading={isLoading}
        disabled={!dataTermsAccepted}
      >
        {t.common.accept}
      </Button>
    </Styles>
  );
};

PrivacyPolitics.defaultProps = {};

export default PrivacyPolitics;
