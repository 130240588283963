// usePendingOrder customHook

import { Storage } from "@capacitor/storage";
import { fetchCheckPendingPayments } from "@multicines/services";
import { fetchOrderDetails, usePutCancelOrder } from "@multicines/services";
import { fetchIncompleteOrders } from "@multicines/services";
import { useCataloguesStore } from "@multicines/stores";
import { OrderDetails } from "@multicines/types";
import { SNACKS_VENDOR, TICKETS_VENDOR } from "@multicines/utils";
import { getApiMessageError, getPlaceToPayReference } from "@multicines/utils";
import { useCallback, useMemo } from "react";

import useI18n from "./useI18n";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const { PENDING_ORDER_TOKEN } = CONSTANTS.STORAGE;

const usePendingOrder = () => {
  const uid = useAuthStore(state => state.uid);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const { mutateAsync: putCancelOrder, reset } = usePutCancelOrder({
    isAnonymous,
    uid
  });
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const t = useI18n();

  const [ticketStore, snacksStore] = selectedStore ?? [];
  const { vendor } = snacksStore ?? {};
  const { id: selectedSnackVendorId } = vendor ?? {};
  const { vendor: ticketVendor } = ticketStore ?? {};
  const { id: selectedTicketVendorId } = ticketVendor ?? {};

  const getIdIncompleteOrder = useCallback(async () => {
    const [incompleteTicketsOrder] = await fetchIncompleteOrders(
      selectedCatalogueId,
      selectedTicketVendorId ?? TICKETS_VENDOR.id
    );
    const { id: ticketsOrderId } = incompleteTicketsOrder ?? {};
    if (ticketsOrderId) return ticketsOrderId;

    const [incompleteSnacksOrder] = await fetchIncompleteOrders(
      selectedCatalogueId,
      selectedSnackVendorId ?? SNACKS_VENDOR.id
    );
    const { id: snacksOrderId } = incompleteSnacksOrder ?? {};
    return snacksOrderId;
  }, [selectedCatalogueId, selectedSnackVendorId, selectedTicketVendorId]);

  const getInCompleteOrderIds = useCallback(async () => {
    const incompleteTicketsOrders = await fetchIncompleteOrders(
      selectedCatalogueId,
      selectedTicketVendorId ?? TICKETS_VENDOR.id
    );
    const incompleteTicketsOrderIds = incompleteTicketsOrders.map(
      ({ id }) => id
    );

    const incompleteSnacksOrders = await fetchIncompleteOrders(
      selectedCatalogueId,
      selectedSnackVendorId ?? SNACKS_VENDOR.id
    );
    const incompleteSnacksOrderIds = incompleteSnacksOrders.map(({ id }) => id);
    return [...incompleteTicketsOrderIds, ...incompleteSnacksOrderIds];
  }, [selectedCatalogueId, selectedSnackVendorId, selectedTicketVendorId]);

  const getInCompleteOrders = useCallback(async () => {
    const hasPendingPayments = await fetchCheckPendingPayments();

    if (!hasPendingPayments) {
      return [];
    }

    const ids = await getInCompleteOrderIds();
    const orders: OrderDetails[] = [];

    for (const id of ids) {
      const order = await fetchOrderDetails(id);
      orders.push(order);
    }

    return orders;
  }, [getInCompleteOrderIds]);

  const getCancelStatus = useCallback(
    async (pendingId: number) => {
      try {
        const { data } = await putCancelOrder(pendingId);
        if (typeof data !== "object") return;
        const { status } = data ?? {};
        reset();
        return status;
      } catch (error) {
        const message = getApiMessageError(error);
        //INFO: this message is returned when the order is already canceled
        if (message === "La order no puede ser cancelada") {
          return "REJECTED";
        }
        notify(error, "Cancel pending order");
        return message;
      }
    },
    [putCancelOrder, reset]
  );

  const validatePendingOrder = useCallback(
    async (
      onContinue: () => void | Promise<void>,
      onError: (error: string) => void
    ) => {
      const hasPendingPayments = await fetchCheckPendingPayments();

      if (!hasPendingPayments) {
        await onContinue();
        return;
      }

      const pendingId = await getIdIncompleteOrder();
      if (!pendingId) {
        onError(t.purchaseStatus.pendingPayment);
        return;
      }

      const { value } = await Storage.get({
        key: `${PENDING_ORDER_TOKEN}${uid}`
      });
      const pendingBankOrder = (value ? JSON.parse(value) : {}) as OrderDetails;
      const { id: storageId } = pendingBankOrder ?? {};
      const isPendingBanK = storageId === pendingId;
      if (isPendingBanK) {
        const reference = getPlaceToPayReference(pendingBankOrder);
        onError(
          t.purchaseStatus.pendingBanKPayment.replace("{reference}", reference)
        );
        return;
      }

      const status = await getCancelStatus(pendingId);

      if (status === "REJECTED") {
        await onContinue();
        return;
      }

      if (status) {
        onError(status);
        return;
      }

      const orderDetails = await fetchOrderDetails(pendingId);
      const reference = getPlaceToPayReference(orderDetails);
      onError(
        t.purchaseStatus.pendingNoBanKPayment.replace("{reference}", reference)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCancelStatus, getIdIncompleteOrder, uid]
  );

  return useMemo(
    () => ({
      getIdIncompleteOrder,
      getCancelStatus,
      validatePendingOrder,
      getInCompleteOrders
    }),
    [
      getCancelStatus,
      getIdIncompleteOrder,
      getInCompleteOrders,
      validatePendingOrder
    ]
  );
};

export default usePendingOrder;
