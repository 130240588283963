import { useShoppingCartStore } from "@multicines/stores";
import React, { useEffect } from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";
import useAuth from "hooks/useAuth/useAuth";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useStoresStore } from "stores/stores/stores.store";
import { cleanShoppingCartAlerts } from "utils/shoppingCart.utils";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;
  const { listenAuthState } = useAuth();
  const hasCart = useShoppingCartStore(state => !!state.shoppingCart);
  const { listenShoppingCart, updateShoppingCart } = useShoppingCart();
  const selectedStore = useStoresStore(store => store.selectedStore);

  useEffect(() => {
    return listenAuthState();
  }, [listenAuthState]);

  useEffect(() => {
    return listenShoppingCart?.();
  }, [listenShoppingCart]);

  // Clean shopping cart alerts if store changes
  useEffect(() => {
    if (!hasCart) return;
    updateShoppingCart?.(cleanShoppingCartAlerts);
  }, [selectedStore, updateShoppingCart, hasCart]);

  return <>{children}</>;
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
