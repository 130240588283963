import { useEffect } from "react";

/**
 * Custom hook that redirects users to open the current URL in the Chrome browser or Safari
 * if they are using the Facebook or Instagram in-app browser.
 *
 * The hook checks the user agent to determine if the user is accessing the site
 * through the Facebook or Instagram app. If so, it attempts to redirect the user
 * to the Chrome browser using the appropriate URL scheme for Android or iOS.
 *
 * For Android, it uses an intent URL to open the link in Chrome.
 * For iOS, it uses the `googlechrome://` URL scheme and sets a timeout to revert
 * back to the original URL if the redirection fails.
 *
 * If the user is not using the Facebook or Instagram app, no redirection occurs.
 *
 * @version 1.0.0
 */
export const useWebViewRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || "";

    const isFacebookApp =
      userAgent.includes("FBAN") || userAgent.includes("FBAV");
    const isInstagramApp = userAgent.includes("Instagram");

    if (!isFacebookApp && !isInstagramApp) return;

    const currentUrl = window.location.href;

    if (/Android/i.test(userAgent)) {
      const androidIntent = `intent://${currentUrl.replace(
        "https://",
        ""
      )}#Intent;scheme=https;package=com.android.chrome;end`;
      window.location.href = androidIntent;
      return;
    }

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      const chromeURL =
        "googlechrome://" + currentUrl.replace(/^https?:\/\//, "");
      window.location.href = chromeURL;
      const timeoutId = setTimeout(() => {
        const safariURL = `x-safari-${currentUrl}`;
        window.location.href = safariURL;
      }, 1000);
      return () => clearTimeout(timeoutId);
    }

    alert("Para una mejor experiencia. abre la página en tu navegador");
  }, []);
};
