import styled from "styled-components";

import { ErrorNotificationStyledProps as Props } from "./ErrorNotification.types";
import Notification from "components/global/notifications/Notification/Notification";

const ErrorNotificationStyled = styled(Notification)<Props>`
  display: flex;
  padding: 1.2rem;
  gap: 0.8rem;
  border: 0.1rem solid var(--palette-mid-blue-s15-l20);
  cursor: ${props => (props.clickable ? "pointer" : "auto")};
  overflow-x: auto;

  .ErrorNotification {
    &__content {
      flex: 1;
    }

    &__title {
      color: var(--palette-white);
      font-weight: 600;
      line-height: 2rem;
      font-size: 1.6rem;
    }

    &__message {
      font-size: 1.4rem;
      color: var(--palette-white);
      line-height: 2rem;
    }
  }
`;

export default ErrorNotificationStyled;
