// Notifications helper functions and data
import { Catalogue, Product } from "artisn/types";
import { Notification } from "artisn-ui-react";

import AddToCartNotification from "components/global/notifications/AddToCartNotification/AddToCartNotification";
import AddToWishlistNotification from "components/global/notifications/AddToWishlistNotification/AddToWishlistNotification";
import BenefitsNotification from "components/global/notifications/BenefitsNotification/BenefitsNotification";
import ClosedStoreNotification from "components/global/notifications/ClosedStoreNotification/ClosedStoreNotification";
import ErrorNotification from "components/global/notifications/ErrorNotification/ErrorNotification";
import InfoNotification from "components/global/notifications/InfoNotification/InfoNotification";
import NoCoverageNotification from "components/global/notifications/NoCoverageNotification/NoCoverageNotification";
import SuccessNotification from "components/global/notifications/SuccessNotification/SuccessNotification";

export const noCoverageNotificationId = "no-coverage-notification-id";
export const storeClosedNotificationId = "store-closed-notification-id";
export const addToCartNotificationId = "add-to-cart-notification-id";
export const addToWishlistNotificationId = "add-to-wishlist-notification-id";
export const errorNotificationId = "error-notification-id";
export const successNotificationId = "success-notification-id";
export const benefitsNotificationId = "benefits-notification-id";
export const infoNotificationId = "info-notification-id";

export const createInfoNotification = (
  title: string,
  description: string,
  buttonText: string,
  buttonTextMobile: string,
  onButton: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isOpenModal: boolean,
  onClose?: () => void
) => {
  const infoNode = (
    <InfoNotification
      onButton={onButton}
      opened={isOpenModal}
      onClose={onClose}
      title={title}
      description={description}
      buttonText={buttonText}
      buttonTextMobile={buttonTextMobile}
    />
  );
  Notification.create({
    node: infoNode,
    position: "bottom-right",
    name: "info",
    method: "start",
    duration: 0,
    dismissOnClick: false,
    id: infoNotificationId
  });
};

export const dismissInfoNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(infoNotificationId, "bottom-right");
};

export const createNoCoverageNotification = (
  onButton: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isOpenModal: boolean,
  onClose?: () => void
) => {
  const noCoverageNode = (
    <NoCoverageNotification
      onButton={onButton}
      opened={!isOpenModal}
      onClose={onClose}
    />
  );

  Notification.create({
    node: noCoverageNode,
    position: "bottom-right",
    name: "noCoverage",
    method: "start",
    duration: 0,
    dismissOnClick: false,
    id: noCoverageNotificationId
  });
};

export const dismissNoCoverageNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(noCoverageNotificationId, "bottom-right");
};

export const createClosedStoreNotification = (
  catalogue: Catalogue["name"],
  onButton: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isOpenModal: boolean,
  onClose?: () => void
) => {
  const closedStoreNode = (
    <ClosedStoreNotification
      onButton={onButton}
      catalogue={catalogue}
      opened={!isOpenModal}
      onClose={onClose}
    />
  );

  Notification.create({
    node: closedStoreNode,
    position: "bottom-right",
    name: "closedStore",
    method: "start",
    duration: 0,
    dismissOnClick: false,
    id: storeClosedNotificationId
  });
};

export const dismissClosedStoreNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(storeClosedNotificationId, "bottom-right");
};

export const createErrorNotification = (
  message: string,
  title?: string,
  onClick?: () => void,
  duration = 20000
) => {
  const errorNode = (
    <ErrorNotification title={title} message={message} clickable={!!onClick} />
  );

  Notification.create({
    node: errorNode,
    position: "bottom-right",
    name: "error",
    method: "start",
    dismissOnClick: false,
    id: errorNotificationId,
    onClick,
    duration
  });
};

export const dismissErrorNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(errorNotificationId, "bottom-right");
};

export const createAddToCartNotification = (
  product?: Product,
  showDrawer?: () => void,
  modifiable?: boolean
) => {
  const addToCartNode = (
    <AddToCartNotification
      product={product}
      showDrawer={showDrawer}
      modifiable={modifiable}
    />
  );

  Notification.create({
    node: addToCartNode,
    position: "bottom-right",
    name: "addToCart",
    method: "start",
    dismissOnClick: false,
    id: addToCartNotificationId
  });
};

export const dismissAddToCartNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(addToCartNotificationId, "bottom-right");
};

export const createAddToWishlistNotification = (
  product?: Product,
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
) => {
  const addToWishlistNode = (
    <AddToWishlistNotification product={product} onDelete={onDelete} />
  );

  Notification.create({
    node: addToWishlistNode,
    position: "bottom-right",
    name: "addToWishlist",
    method: "start",
    dismissOnClick: false,
    id: addToWishlistNotificationId
  });
};

export const dismissAddToWishlistNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(addToWishlistNotificationId, "bottom-right");
};

export const createSuccessNotification = (
  title: string,
  message?: string,
  duration?: number,
  isSubscription?: boolean
) => {
  const successNode = (
    <SuccessNotification
      title={title}
      message={message}
      isSubscription={isSubscription}
    />
  );

  Notification.create({
    node: successNode,
    position: "bottom-right",
    name: "success",
    method: "start",
    dismissOnClick: true,
    id: successNotificationId,
    duration
  });
};

export const createBenefitsNotification = (
  title: string,
  message?: string,
  duration?: number,
  onClick?: () => void,
  isAnonymous?: boolean
) => {
  const benefitsNode = (
    <BenefitsNotification
      title={title}
      message={message}
      onClick={onClick}
      isAnonymous={isAnonymous}
    />
  );

  Notification.create({
    node: benefitsNode,
    position: "bottom-right",
    name: "success",
    method: "start",
    dismissOnClick: true,
    id: benefitsNotificationId,
    onClick,
    duration
  });
};

export const dismissBenefitsNotification = () => {
  if (typeof Notification.dismiss !== "function") return;
  Notification.dismiss(benefitsNotificationId, "bottom-right");
};
